const allow_to_add_walk_in_order_dishes_NamePath = [
  'smart_ordering',
  'tablet_config',
  'allow_to_add_walk_in_order_dishes',
];
const enable_smart_ordering_prefix_NamePath = ['smart_ordering', 'tablet_config', 'enable_smart_ordering_prefix'];
const dineInEnableNamePath = ['smart_ordering', 'tablet_config', 'dine_in_config', 'enable'];
const dineInOnlinePaymentTypeNamePath = ['smart_ordering', 'tablet_config', 'dine_in_config', 'online_payment_type'];
const dineInOnlinePaymentEnable = ['smart_ordering', 'tablet_config', 'dine_in_config', 'online_payment_enable'];
const dineInOnlinePaymentImmediately = [
  'smart_ordering',
  'tablet_config',
  'dine_in_config',
  'online_payment_immediately',
];
const displayDishRatingsNamePath = ['smart_ordering', 'tablet_config', 'show_dish_ratings'];
const displayChowbusCategoriesNamePath = ['smart_ordering', 'tablet_config', 'show_generated_categories'];
const displayDishForeignNamePath = ['smart_ordering', 'tablet_config', 'display_dish_foreign_names'];
const displaySoldoutDishesAndCustomizationsNamePath = ['smart_ordering', 'tablet_config', 'display_sold_out_dishes'];
const enableCustomizationLimitPropertiesPath = [
  'smart_ordering',
  'tablet_config',
  'enable_customization_limit_properties',
];
const needPinUnlockMenusNamePath = ['smart_ordering', 'tablet_config', 'need_pin_unlock_menus'];
const pinCodeNamePath = ['smart_ordering', 'tablet_config', 'pin_code'];
const enableTabletScreenSaverPath = ['smart_ordering', 'tablet_config', 'show_screen_saver'];
const enableTabletShowTotalPriceOnSubmitButtonPath = ['smart_ordering', 'tablet_config', 'show_total_price'];
const showQRCodeOnTabletPath = ['smart_ordering', 'tablet_config', 'show_qr_code'];
const enableTabletOriginalPriceStrikeThroughtPath = [
  'smart_ordering',
  'tablet_config',
  'show_original_price_strikethrough',
];

// 平板自助餐相关字段name path
const buffetOrderingLimit = ['smart_ordering', 'tablet_config', 'buffet_ordering_limit'];
const inputGuestNumberPath = ['smart_ordering', 'tablet_config', 'input_party_size'];
const limitItemPurchaseQuantitiesByGuest = [
  'smart_ordering',
  'tablet_config',
  'limit_item_purchase_quantities_by_guest',
];
const needMaxItemsOnOrder = ['smart_ordering', 'tablet_config', 'need_max_items_on_order'];
const maxItemsOnOrder = ['smart_ordering', 'tablet_config', 'max_items_on_order'];
const needDiningTimeLimit = ['smart_ordering', 'tablet_config', 'need_dining_time_limit'];
const diningTimeLimit = ['smart_ordering', 'tablet_config', 'dining_time_limit'];
const needDisplayCountdownReminder = ['smart_ordering', 'tablet_config', 'need_display_countdown_reminder'];
const displayCountdownReminder = ['smart_ordering', 'tablet_config', 'display_countdown_reminder'];
const needSubmitRoundsInterval = ['smart_ordering', 'tablet_config', 'need_submit_rounds_interval'];
const submitRoundsInterval = ['smart_ordering', 'tablet_config', 'submit_rounds_interval'];
const needMaxRoundsPerOrder = ['smart_ordering', 'tablet_config', 'need_max_rounds_per_order'];
const maxRoundsPerOrder = ['smart_ordering', 'tablet_config', 'max_rounds_per_order'];
const shoppingCartLimit = ['smart_ordering', 'tablet_config', 'shopping_cart_limit'];
const needMaxItemsPerRound = ['smart_ordering', 'tablet_config', 'need_max_items_per_round'];
const maxItemsPerRound = ['smart_ordering', 'tablet_config', 'max_items_per_round'];
const limitItemNotRestricted = ['smart_ordering', 'tablet_config', 'limit_item_not_restricted'];
const enableSwitchTable = ['smart_ordering', 'tablet_config', 'enable_switch_table'];
const menuDecorationLayoutNamePath = ['smart_ordering', 'tablet_config', 'menu_decoration_layout'];
const enableOrderLevelNamePath = ['smart_ordering', 'tablet_config', 'enable_order_note'];
const enableMealLevelNamePath = ['smart_ordering', 'tablet_config', 'enable_dish_note'];
const showCategoryForLandscapeMenuDecrationNamePath = [
  'smart_ordering',
  'tablet_config',
  'menu_decoration_show_category_for_landscape',
];
const showCategoryForPortraitMenuDecorationNamePath = [
  'smart_ordering',
  'tablet_config',
  'menu_decoration_show_category_for_portrait',
];

const applyPreferencesPath = ['smart_ordering', 'tablet_config', 'apply_dish_preference'];

const TabletPreferenceNamePath = {
  allow_to_add_walk_in_order_dishes_NamePath,
  enable_smart_ordering_prefix_NamePath,
  dineInEnableNamePath,
  dineInOnlinePaymentTypeNamePath,
  dineInOnlinePaymentEnable,
  dineInOnlinePaymentImmediately,
  displayDishRatingsNamePath,
  displayChowbusCategoriesNamePath,
  displayDishForeignNamePath,
  displaySoldoutDishesAndCustomizationsNamePath,
  needPinUnlockMenusNamePath,
  pinCodeNamePath,
  enableTabletScreenSaverPath,
  enableCustomizationLimitPropertiesPath,
  enableTabletShowTotalPriceOnSubmitButtonPath,
  showQRCodeOnTabletPath,
  enableTabletOriginalPriceStrikeThroughtPath,
  inputGuestNumberPath,
  buffetOrderingLimit,
  limitItemPurchaseQuantitiesByGuest,
  needMaxItemsOnOrder,
  maxItemsOnOrder,
  needDiningTimeLimit,
  diningTimeLimit,
  needDisplayCountdownReminder,
  displayCountdownReminder,
  needSubmitRoundsInterval,
  submitRoundsInterval,
  needMaxRoundsPerOrder,
  maxRoundsPerOrder,
  shoppingCartLimit,
  needMaxItemsPerRound,
  maxItemsPerRound,
  limitItemNotRestricted,
  enableSwitchTable,
  menuDecorationLayoutNamePath,
  showCategoryForLandscapeMenuDecrationNamePath,
  showCategoryForPortraitMenuDecorationNamePath,
  applyPreferencesPath,
  enableOrderLevelNamePath,
  enableMealLevelNamePath,
};
export default TabletPreferenceNamePath;
