import { GET, POST, PUT } from '../utils/request';
import { API_PREFIX, CLIENT_ID, MONOLITH_API_PREFIX } from '../consts';
import { CATALOG_SERVICE_API_PREFIX, ONLINE_ORDERING_API_PREFIX } from 'src/consts/third_party';

export function queryRestaurantSettings() {
  return GET(
    `${API_PREFIX}/v1/setting`,
    {},
    {
      silence: true,
    }
  );
}

export function queryDishItemTicketMapping(mapping_types) {
  return GET(`${API_PREFIX}/v3/dishitem/ticket_mapping`, { mapping_types });
}

export function updateDishItemTicketMapping(data) {
  return POST(`${API_PREFIX}/v3/dishitem/ticket_mapping`, data);
}

export function updateRestaurantSettings(payload) {
  return POST(`${API_PREFIX}/v1/setting`, payload);
}

export function queryRestaurantInfo() {
  return GET(`${API_PREFIX}/v1/restaurant/info`);
}

export function initRestaurant() {
  return POST(`${API_PREFIX}/v2/restaurant/init`);
}

export function getTableQrCodeShortLinks({
  secret_code,
  table_ids,
  is_quick_service = false,
  is_restaurant_level_code = false,
  is_browse_mode_code = false,
}) {
  return POST(`${API_PREFIX}/v1/qr/link`, {
    secret_code,
    table_ids,
    is_quick_service,
    is_restaurant_level_code,
    is_browse_mode_code,
  });
}

export function updateRestaurantWifiSetting({ restaurant_id, wifi_ssid, wifi_password, sales_category_config_mode }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurant_id}`,
    {
      wifi_ssid,
      wifi_password,
      sales_category_config_mode,
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryRestaurantWifiSetting({ restaurant_id }) {
  return GET(
    `${MONOLITH_API_PREFIX}/api/pos/v4/restaurants/${restaurant_id}`,
    {},
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function queryOnlineOrderingSettings() {
  return GET(`${ONLINE_ORDERING_API_PREFIX}/api/v1/setting`);
}

export function updateOnlineOrderingPreference(preference) {
  return PUT(`${ONLINE_ORDERING_API_PREFIX}/api/v1/setting/preference`, preference);
}

export function updateOnlineOrderingHourSettings(hourSettings) {
  return PUT(`${ONLINE_ORDERING_API_PREFIX}/api/v1/setting/hour`, hourSettings);
}

export function updateOnlineOrderSyncLinkToGfoSettings({ restaurant_id, sync_gfo_switch }) {
  return PUT(
    `${MONOLITH_API_PREFIX}/api/restaurant/v2/restaurants/${restaurant_id}/restaurant_contracts`,
    {
      distribution_mode: 'white_label',
      restaurant_contract: {
        sync_gfo_switch,
      },
    },
    {
      headers: {
        'client-id': CLIENT_ID,
        'service-region-id': 1,
      },
      parseAsJSONApi: true,
    }
  );
}

export function getThirdPartySettings(restaurantId) {
  return GET(`${CATALOG_SERVICE_API_PREFIX}/api/v1/pos_dashboard/restaurants/${restaurantId}/third_party_setting`);
}

export function updateThirdPartySettings(restaurantId, payload) {
  return PUT(
    `${CATALOG_SERVICE_API_PREFIX}/api/v1/pos_dashboard/restaurants/${restaurantId}/third_party_setting`,
    payload
  );
}

/**
 * type MenuPayload = { menu_id: string; menu_name: string }
 * @param {Object} params
 * @param {String} params.restaurant_id 餐厅id
 * @param {String} params.channel_id 渠道id
 * @param {String} params.channel_name 渠道名
 * @param {MenuPayload[]} params.publish_menus 菜单ids
 * @returns
 */
export function publishThirdPartyMenu({ restaurant_id, channel_id, channel_name, publish_menus }) {
  return POST(`${CATALOG_SERVICE_API_PREFIX}/api/v1/pos_dashboard/restaurants/${restaurant_id}/menus/publish_menus`, {
    channel_id,
    channel_name,
    publish_menus,
  });
}
