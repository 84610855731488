import {
  queryRestaurantSettings,
  queryRestaurantInfo,
  queryRestaurantWifiSetting,
  updateRestaurantWifiSetting,
  updateRestaurantSettings,
  updateOnlineOrderingPreference,
} from '../services/settings';
import { queryMixpanelLogToggle } from '../services/mixpanelFeature';
import { getSessionItem, KEYS, setSessionItem, USER_KEYS } from '../store/storage';
import { checkIfOwnPOSContract, checkIfOwnWebsiteContract } from '../utils/utils';
import { ALL_PLATFORMS, PLATFORM_WEBSITE, PLATFORM_BRANDED_APP } from '../consts';
import { queryEmployeeWorkflowSetting, updateEmployeeWorkflowSetting } from '../services/employeeWorkflowSetting';
import { fetchRestaurantPreference } from './preference';
import { updateStartOfWeekForMoment } from 'src/utils/time';
import { queryOnlineOrderingSettings } from 'src/services/settings';

const BASE = 'SETTING';
const DEFAULT_CUTOFF_TIME = '23:59:59';

export const actions = {
  loadingRestaurantProfile: `${BASE}_LOADING_RESTAURNT_PROFILE`,
  loadRestaurantProfileSuccess: `${BASE}_LOAD_RESTAURNT_PROFILE_SUCCESS`,
  loadRestaurantProfileFailed: `${BASE}_LOAD_RESTAURNT_PROFILE_FAILED`,
  updateRestaurantInfo: `${BASE}_UPDATE_RESTAURANT_INFO`,
  updateRestaurantSettings: `${BASE}_UPDATE_RESTAURANT_SETTINGS`,
  loadingEmployeeWorkflowSettings: `${BASE}_LOADING_EMPLOYEE_WORKFLOW_SETTINGS`,
  loadEmployeeWorkflowSettingsSuccess: `${BASE}_LOAD_EMPLOYEE_WORKFLOW_SETTINGS_SUCCESS`,
  loadEmployeeWorkflowSettingsFailed: `${BASE}_LOAD_EMPLOYEE_WORKFLOW_SETTINGS_FAILED`,
  updatingEmployeeWorkforceSettings: `${BASE}_UPDATING_EMPLOYEE_WORKFLOW_SETTINGS`,
  updateEmployeeWorkforceSettingsDone: `${BASE}_UPDATE_EMPLOYEE_WORKFLOW_SETTINGS_DONE`,
  updateEmployeeWorkforceSettingsSuccess: `${BASE}_UPDATE_EMPLOYEE_WORKFLOW_SETTINGS_SUCCESS`,
  updateEmployeeWorkforceSettingsFailed: `${BASE}_UPDATE_EMPLOYEE_WORKFLOW_SETTINGS_FAILED`,
  setActivatedStatus: `${BASE}_SET_ACTIVATED_STATUS`,
  loadingOnlineOrderingSettings: `${BASE}_LOADING_ONLINE_ORDERING_SETTINGS`,
  loadOnlineOrderingSettingsSuccess: `${BASE}_LOAD_ONLINE_ORDERING_SETTINGS_SUCCESS`,
  loadOnlineOrderingSettingsFailed: `${BASE}_LOAD_ONLINE_ORDERING_SETTINGS_FAILED`,
  updatingOnlineOrderingSettings: `${BASE}_UPDATING_ONLINE_ORDERING_SETTINGS`,
  updateOnlineOrderingSuccess: `${BASE}_UPDATE_ONLINE_ORDERING_SUCCESS`,
  updateOnlineOrderingFailed: `${BASE}_UPDATE_ONLINE_ORDERING_FAILED`,
};

export const fetchRestaurantProfile = (loadRestaurantPreference) => async (dispatch, getState) => {
  const { isLoadingRestaurantProfile, isLoadRestaurantProfileDone } = getState().setting;
  if (isLoadingRestaurantProfile || isLoadRestaurantProfileDone) return;

  dispatch({ type: actions.loadingRestaurantProfile });

  const promises = [queryRestaurantInfo(), queryRestaurantSettings(), queryMixpanelLogToggle()];

  let result;

  try {
    result = await Promise.all(promises);
  } catch (e) {
    result = null;
  }

  if (!result) {
    dispatch({ type: actions.loadRestaurantProfileFailed });
    return;
  }

  const [restaurantInfoResponse, restaurantSettingsResponse, queryMixpanelLogTogglePreferenceResponse] = result;
  const queryMixpanelLogTogglePreference = queryMixpanelLogTogglePreferenceResponse.data || { switch: false };
  const restaurantInfo = restaurantInfoResponse.data || {};
  const hasPOSContract = checkIfOwnPOSContract(restaurantInfo);
  const settingData = restaurantSettingsResponse.data || {};
  if (!settingData.closeout_report_cutoff_time) settingData.closeout_report_cutoff_time = DEFAULT_CUTOFF_TIME;
  const locale = getSessionItem(KEYS.locale);
  updateStartOfWeekForMoment(locale, settingData.start_of_week);

  settingData.enableMixpanelTracking = queryMixpanelLogTogglePreference.switch;

  setSessionItem({
    [USER_KEYS.signedPOSContract]: hasPOSContract,
    [USER_KEYS.zoneInfo]: restaurantInfo.iana_time_zone,
    [USER_KEYS.activated]: !!settingData.id,
  });
  dispatch({
    type: actions.setActivatedStatus,
    payload: !!settingData.id,
  });
  const onlinePlatforms = [...ALL_PLATFORMS, PLATFORM_WEBSITE, PLATFORM_BRANDED_APP];

  dispatch({
    type: actions.loadRestaurantProfileSuccess,
    payload: {
      restaurantInfo,
      onlinePlatforms,
      settings: settingData,
    },
  });

  if (loadRestaurantPreference && hasPOSContract) {
    dispatch(fetchRestaurantPreference());
  }
};

export const doUpdateRestaurantSettings = (payload, callback) => async (dispatch, getState) => {
  let response;
  try {
    response = await updateRestaurantSettings(payload);
  } catch (e) {
    response = { success: false };
  }

  if (typeof callback === 'function') callback(response);
  if (!response.success) return;

  const state = getState();
  const { settings } = state.setting;
  const newSettings = { ...(settings || {}), ...payload };
  dispatch({
    type: actions.updateRestaurantSettings,
    payload: newSettings,
  });

  if (!('start_of_week' in payload)) return;

  const { locale } = state.app;
  updateStartOfWeekForMoment(locale, payload.start_of_week);
};

export const fetchRestaurantWifiSetting =
  ({ restaurant_id }, callback) =>
  async () => {
    let response;
    try {
      response = await queryRestaurantWifiSetting({ restaurant_id });
    } catch (e) {
      response = { success: false };
    }
    if (typeof callback === 'function') callback(response);
  };

export const doUpdateRestaurantWifiSetting = (data, callback) => async (dispatch) => {
  let response;
  try {
    response = await updateRestaurantWifiSetting(data);
  } catch (e) {
    response = { success: false };
  }

  if (response.success) {
    let { wifi_ssid, wifi_password, sales_category_config_mode } = data || {};
    dispatch({
      type: actions.updateRestaurantInfo,
      payload: { wifi_ssid, wifi_password, sales_category_config_mode },
    });
  }

  if (typeof callback === 'function') callback(response);
};

export const fetchEmployeeWorkforceSettings = () => async (dispatch, getState) => {
  const { isLoadEmployeeWorkflowSettingSuccess } = getState().setting;
  if (isLoadEmployeeWorkflowSettingSuccess) return;

  dispatch({ type: actions.loadingEmployeeWorkflowSettings });

  let response;

  try {
    const restaurantId = getSessionItem(USER_KEYS.restaurantId);
    response = await queryEmployeeWorkflowSetting(restaurantId);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadEmployeeWorkflowSettingsFailed,
    });
    return;
  }

  dispatch({
    type: actions.loadEmployeeWorkflowSettingsSuccess,
    payload: response.data || {},
  });
};

export const doUpdateEmployeeWorkforceSettings = (payload, callback) => async (dispatch) => {
  dispatch({ type: actions.updatingEmployeeWorkforceSettings });

  let response;

  try {
    response = await updateEmployeeWorkflowSetting(payload);
  } catch (e) {
    response = { success: false };
  }

  typeof callback === 'function' && callback(response);

  if (!response.success) {
    dispatch({ type: actions.updateEmployeeWorkforceSettingsFailed });
    return;
  }

  dispatch({
    type: actions.updateEmployeeWorkforceSettingsSuccess,
    payload,
  });
};

export const fetchOnlineOrderingSettings = (refresh, callback) => async (dispatch, getState) => {
  if (!refresh) {
    const { isLoadOnlineOrderingSettingsSuccess, onlineOrderingSettings } = getState().setting;
    if (isLoadOnlineOrderingSettingsSuccess) {
      typeof callback === 'function' && callback(onlineOrderingSettings);
      return;
    }
  }

  dispatch({ type: actions.loadingOnlineOrderingSettings });

  let response;

  try {
    response = await queryOnlineOrderingSettings();
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({
      type: actions.loadOnlineOrderingSettingsFailed,
    });
    return;
  }

  const settings = response.data || {};
  dispatch({
    type: actions.loadOnlineOrderingSettingsSuccess,
    payload: settings,
  });
  typeof callback === 'function' && callback(settings);
};

export const doUpdateOnlineOrderingPreference = (payload) => async (dispatch, getState) => {
  dispatch({ type: actions.updatingOnlineOrderingSettings });
  let response;

  try {
    response = await updateOnlineOrderingPreference(payload);
  } catch (e) {
    response = { success: false };
  }

  if (!response.success) {
    dispatch({ type: actions.updateOnlineOrderingFailed });
    return;
  }

  const { onlineOrderingSettings } = getState().setting;
  const newOnlineOrderingSettings = { ...onlineOrderingSettings };
  newOnlineOrderingSettings.preference = payload;
  dispatch({
    type: actions.updateOnlineOrderingSuccess,
    payload: newOnlineOrderingSettings,
  });
};
